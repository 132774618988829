import React, { useState, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import AppContext from '../AppContext';

import useLine from '../useLine';

import { api_host } from '../config';

const CustomerStore = () => {
  const appContext = useContext(AppContext);
  const [ id, setId ] = useState('');
  const transformer = async () => {
    try{
      if(id === '')
        return [];
      else{
        return (await axios.get(`${api_host}api/customers`, {
          params: {
            id
          }
        })).data.data.map(item => ({
          ...item,
          date_birth: moment(item.date_birth,'YYYY-MM-DD').format('DD-MM-YYYY')
        }));

      }
    }catch(err){
      appContext.addNotifs('danger', err.response.data.error, 3000);
      return [];
    }
  };

  const [ computed, trigger, setter, isLoading ] = useLine([], transformer);

  return {
    computed,
    isLoading,
    deleteById: trigger(async (id) => {
      try{
        await axios.delete(`${api_host}api/customers?id=${id}`);
        appContext.addNotifs('success', 'Pengguna berhasil dihapus',3000);
      }catch(err){
        appContext.addNotifs('danger', err.response.data.error, 3000);
        return false;
      }
    }),
    searchById: trigger(name => {
      setId(name);
    })
  };
};

export default CustomerStore;
