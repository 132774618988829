import React from 'react';
import { Fade, Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ loading }) => (
  <Fade in={loading} style={{
    position: 'absolute',
    right: '10px',
    top: '68px'
  }}>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Fade>
);

export default LoadingSpinner;