import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppContext from '../AppContext';
import axios from 'axios';
import Show from '../components/Show'
import { api_host } from '../config';

const LoginPage = ({ history }) => {
  const appContext = useContext(AppContext);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const handleUsernameChange = (ev) => {
    setUsername(ev.target.value);
  };
  const handlePasswordChange = (ev) => {
    setPassword(ev.target.value);
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try{
      setIsLoading(true);
      const response = await axios.post(`${api_host}api/admins/login`,{
        username,
        password
      });
      setIsLoading(false);
      if(response.status === 200){
        appContext.doLogin(response.data.token);
        history.push('/');
      }else{
        appContext.addNotifs('danger', 'Login Gagal: Gangguan Server', 3000);
      }
    }catch(err){
      appContext.addNotifs('danger', `Login Gagal: ${err.response.data.error}`, 3000);
    }
  };
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={handleUsernameChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isLoading}>
          <Show show={isLoading}>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Show>
          Login
        </Button>
      </Form>
      </Modal.Body>
    </Modal.Dialog>
  );
};

export default withRouter(LoginPage);
