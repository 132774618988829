import React, { useState } from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import axios from 'axios';
import AppContext from './AppContext';
import AppointmentPage from './pages/appointment.page';
import ReportPage from './pages/report.page';
import LoginPage from './pages/login.page';
import Notification from './components/Notification';
import Show from './components/Show';
import CustomerPage from './pages/customer.page';

function App() {
  const [ notifs, setNotifs ] = useState([]);
  const token = window.localStorage.getItem('token');
  const [ loggedIn, setLoggedIn ] = useState(token !== null);
  let notifsCount = 0;
  let user = {};
  if(loggedIn){
    axios.defaults.headers.common = {
      "Authorization": `Bearer ${window.localStorage.getItem('token')}`
    };
    user = JSON.parse(atob(token.split('.')[1]));
  }
  const [ username, setUsername ] = useState(user.username || null);
  const state = {
    loggedIn,
    polyclinics: {
      1: [1, 'Poli Umum'],
      2: [2, 'Poli MTBS'],
      3: [3, 'Poli Gigi'],
      4: [4, 'Poli KIA'],
      5: [5, 'Poli KB'],
    },
    areas: {
      1: [1, 'Ciumbeuluit'],
      2: [2, 'Hegarmanah'],
      3: [3, 'Di luar wilayah'],
    },
    addNotifs: (variant, message, timeout) => {
      const id = notifsCount++;
      setNotifs([
        ...notifs,
        { variant, message, timeout, id }
      ]);
    },
    removeNotifs: ( id ) => {
      setNotifs(notifs.filter( item => id !== item.id))
    },
    doLogin: (token) => {
      window.localStorage.setItem('token', token)
      const user = JSON.parse(atob(token.split('.')[1]));
      axios.defaults.headers.common = {
        "Authorization": `Bearer ${token}`
      };
      setUsername(user.username);
      setLoggedIn(true);
    },
    doLogout: () => {
      window.localStorage.clear();
      axios.defaults.headers.common = null;
      setLoggedIn(false);
    }
  };

  const AuthOnly = (page) => () => (loggedIn)?page:<Redirect to="/login" />
  const UnauthOnly = (page) => () => (!loggedIn)?page:<Redirect to="/" />

  return (
    <AppContext.Provider value={state}>
      <Router>
        <Navbar expand="lg" bg="dark" variant="dark" style={{
          marginBottom: '30px'
        }}>
          <Navbar.Brand href="#">
            Puskesmas
          </Navbar.Brand>
          <Show show={loggedIn}>
            <Navbar.Toggle/>
            <Navbar.Collapse className="justify-content-end">
              <Route render={({history}) => (
                <Nav className="mr-auto"> 
                  <Nav.Link 
                    active={history.location.pathname === '/'} 
                    onClick={() => history.push('/')}
                  >
                    Beranda
                  </Nav.Link>
                  <Nav.Link 
                    active={history.location.pathname === '/appointments'} 
                    onClick={() => history.push('/appointments')}
                  >
                    Janji Temu
                  </Nav.Link>
                  <Nav.Link 
                    active={history.location.pathname === '/report'} 
                    onClick={() => history.push('/report')}
                  >
                    Laporan
                  </Nav.Link>
                  <Nav.Link 
                    active={history.location.pathname === '/customers'} 
                    onClick={() => history.push('/customers')}
                  >
                    Administrasi
                  </Nav.Link>
                </Nav>
              )}/>
              <Navbar.Text>
                Signed in as: <strong>{username}</strong>
              </Navbar.Text>
              <Button size="sm" onClick={() => state.doLogout()} style={{ marginLeft: '10px'}}>Logout</Button>
            </Navbar.Collapse>
          </Show>
        </Navbar>
        <Container>
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              zIndex: 10,
            }}
          >
            { notifs.map( (notif, idx) => (
                <Notification 
                  key={idx} 
                  {...notif}
                  onClose={state.removeNotifs.bind(null,notif.id)}
                />
              ))
            }
          </div>
          <Route path='/' exact render={AuthOnly(<AppointmentPage/>)}></Route>
          <Route path='/appointments' render={AuthOnly(<AppointmentPage/>)}></Route>
          <Route path='/report' render={AuthOnly(<ReportPage/>)}></Route>
          <Route path='/customers' render={AuthOnly(<CustomerPage/>)}></Route>
          <Route path='/login' component={UnauthOnly(<LoginPage/>)}></Route>
        </Container>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
