import React, { useState, useContext } from 'react';
import AppointmentStore from '../stores/appointment.store';
import AppContext from '../AppContext';
import { 
  Row, Col, Table, Pagination, Dropdown
} from 'react-bootstrap';
import ToggleButton from '../components/ToggleButton';
import TableHeader from '../components/TableHeader';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import moment from 'moment';

const AppointmentPage = () =>{
  const appContext = useContext(AppContext);
  const [ activePoly, setActivePoly ] = useState(appContext.polyclinics[1]);
  const [ activePolyId, activePolyName ] = activePoly;
  const [ currentDate, setCurrentDate ] = useState(moment().format('YYYY-MM-DD'));

  const {
    computed,
    update,
    isLoading,
    prevDate,
    nextDate,
    date,
    orderDirection,
    orderField,
    filterValue,
    setFilterValue,
    setOrderDirection,
    setOrderField,
    firstPage,
    lastPage,
    currentPage,
    numberOfPage,
    prevPage,
    nextPage
  } = AppointmentStore(activePolyId, currentDate);

  const apsStat = (medrec_found,arrived) => {
    if(medrec_found === true && arrived === true)
      return 'aps-done';
    else if(medrec_found === false && arrived === true)
      return 'aps-danger';
    else if(medrec_found === true && arrived === false)
      return 'aps-safe';
    else
      return '';
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleOrderChange = (field) => {
    if(orderField === field)
      setOrderDirection();
    else
      setOrderField(field);
  };

  return (
    <>
      <LoadingSpinner loading={isLoading} />
      <Row>
        <Col>
          <h2>Janji Temu</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Dropdown>
            <Dropdown.Toggle size="lg">
              {activePolyName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              { Object.keys(appContext.polyclinics).map( (pId, idx) => (
                <Dropdown.Item key={idx} onClick={() => setActivePoly(appContext.polyclinics[pId])}>
                  {appContext.polyclinics[pId][1]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <Pagination className="justify-content-end" size="lg">
            <Pagination.Prev onClick={prevDate}/>
            <Pagination.Item style={{
              minWidth: '300px',
              textAlign: 'center'
            }}>
              {date}
              </Pagination.Item>
            <Pagination.Next onClick={nextDate}/>
          </Pagination>
        </Col>
      </Row>
      <Table bordered>
        <thead>
          <tr>
            <TableHeader
              field="q_num"
              orderDirection={orderDirection}
              orderField={orderField}
              onClick={handleOrderChange}
            >
              No. Urut
            </TableHeader>
            <TableHeader
              field="customer_name"
              orderDirection={orderDirection}
              orderField={orderField}
              onClick={handleOrderChange}
            >
              Nama
            </TableHeader>
            <TableHeader
              field="date_birth"
              orderDirection={orderDirection}
              orderField={orderField}
              onClick={handleOrderChange}
            >
              Tanggal Lahir
            </TableHeader>
            <TableHeader
              field="medrec"
              orderDirection={orderDirection}
              orderField={orderField}
              onClick={handleOrderChange}
            >
              Rekam Medis
            </TableHeader>
            <TableHeader
              field="bpjs"
              orderDirection={orderDirection}
              orderField={orderField}
              onClick={handleOrderChange}
            >
              BPJS
            </TableHeader>
            <td>
              <input
                type="text" 
                className="form-control" 
                placeholder="Cari..."
                value={filterValue}
                onChange={handleFilterChange}
              />
            </td>
          </tr>
        </thead>
        <tbody>
        {computed.map( row => (
          <tr key={row.id} className={apsStat(row.medrec_found, row.arrived)}>
            <td>{row.q_num}</td>
            <td>{row.customer_name}</td>
            <td>{row.date_birth}</td>
            <td>{row.medrec}</td>
            <td>{row.bpjs}</td>
            <td>
              <ToggleButton 
                isLoading={isLoading}
                value={row.arrived}
                onClick={() => {
                  update(row.id, {
                    arrived: !row.arrived
                  });
                }}
                >
                  Hadir
              </ToggleButton>
              <ToggleButton 
                isLoading={isLoading}
                value={row.medrec_found}
                onClick={() => {
                  update(row.id, {
                    medrec_found: !row.medrec_found
                  });
                }}
                >
                Rekam Medis
              </ToggleButton>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <Row>
        {/* <Col>
          <Button size="sm" onClick={() => setExportModalShow(true)}>Buat Laporan</Button>
        </Col> */}
        <Col>
          <Pagination className="justify-content-end" size="sm">
            <Pagination.First onClick={firstPage}/>
            <Pagination.Prev onClick={prevPage}/>
            <Pagination.Item>
              {currentPage} / {numberOfPage}
              </Pagination.Item>
            <Pagination.Next onClick={nextPage}/>
            <Pagination.Last onClick={lastPage}/>
          </Pagination>
        </Col>
      </Row>
      {/* <Modal show={exportModalShow} onHide={() => setExportModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Laporan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Tanggal Awal</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Tanggal Akhir</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleExportReport}>
            Unduh Laporan
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default withRouter(AppointmentPage);
