import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

const Notification = (props) => {
  const [ vis, setVis ] = useState(true);
  const onClose = () => {
    setVis(false);
  }
  if(props.timeout)
    setTimeout(onClose, props.timeout);
  if(vis){
    return (
      <Alert show={vis}  variant={props.variant} onClose={onClose} dismissible>
        {props.message}
      </Alert>
    );
  }else{
    return null;
  }
};

export default Notification;
