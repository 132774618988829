import React, { useState, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Row, Col ,Button, Form, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import AppContext from '../AppContext';
import LoadingSpinner from '../components/LoadingSpinner';
import { api_host } from '../config';

const ReportPage = () => {
  const appContext = useContext(AppContext);
  const [ startDate, setStartDate ] = useState(moment().subtract(1, 'month').toDate());
  const [ endDate, setEndDate ] = useState(moment().toDate());
  const [ activePolyId, setActivePolyId ] = useState(appContext.polyclinics[1][0]);
  const [ activeAreaId, setActiveAreaId ] = useState(0);
  const [ isArrived, setIsArrived ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleExportReport = async () => {
    try{
      setIsLoading(true);
      const params = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        polyclinic_id: activePolyId,
      };
      if(parseInt(activeAreaId) !== 0)
        params.area_id = activeAreaId;
      if(parseInt(isArrived) !== 0)
        params.arrived = isArrived;
      const { data } = await axios.get(`${api_host}api/appointments/report`,{
        params,
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('A');
      a.href = url;
      a.download = 'Laporan.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }catch(err){
      appContext.addNotifs('danger', 'Gagal membuat laporan', 3000);
    }finally{
      setIsLoading(false);
    }
  };

  const handlePoliChange = (ev) =>{
    console.log(appContext.polyclinics[ev.target.value][0]);
    setActivePolyId(appContext.polyclinics[ev.target.value][0]);
  }

  const handleAreaChange = (ev) => 
    setActiveAreaId(ev.target.value);

  const handleArrivedChange = (ev) => 
    setIsArrived(ev.target.value);

  return (
    <>
      <LoadingSpinner loading={isLoading} />
      <Row>
        <Col>
          <h2>Laporan</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form>
            <Form.Group>
              <Form.Label>Poliklinik</Form.Label>
              <Form.Control as="select" value={activePolyId} onChange={handlePoliChange}>
                { Object.keys(appContext.polyclinics).map( (pId, idx) => (
                  <option key={idx} value={pId}>
                    {appContext.polyclinics[pId][1]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Kelurahan</Form.Label>
              <Form.Control as="select" value={activeAreaId} onChange={handleAreaChange}>
                <option key={-1} value={0}>
                  -- SEMUA --
                </option>
                { Object.keys(appContext.areas).map( (areaId, idx) => (
                  <option key={idx} value={areaId}>
                    {appContext.areas[areaId][1]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Kehadiran</Form.Label>
              <Form.Control as="select" value={isArrived} onChange={handleArrivedChange}>
                <option key={-1} value={0}>
                  -- SEMUA --
                </option>
                <option key={0} value={true}>
                  Hadir
                </option>
                <option key={1} value={false}>
                  Tidak Hadir
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Label>Tanggal Awal</Form.Label>
            <Form.Group>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                selected={startDate}
                popperPlacement="bottom-end"
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                onChange={date => {
                  setStartDate(date);
                }}
              />
            </Form.Group>
            <Form.Label>Tanggal Akhir</Form.Label>
            <Form.Group>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
                maxDate={new Date()}
                selected={endDate}
                popperPlacement="bottom"
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                onChange={date => {
                  setEndDate(date);
                }}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleExportReport}>
              Unduh Laporan
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportPage;
