import { useState, useEffect } from 'react';

const defaultTranformer = data => data

const useLine = (initial = [], transformer = defaultTranformer) => {
  const [ raw, setRaw ] = useState(initial);
  const [ computed, setComputed ] = useState(initial);
  const [ isLoading, setLoading ] = useState(false);
  const [ flag, setFlag ] = useState(1);

  const trigger = ( func ) => {
    return async (...args) => {
      if(isLoading === true)
        return;
      setLoading(true);
      try{
        if(await func(...args) !== false)
          setFlag(1 - flag);
        else
          setLoading(false);
      }catch(err){
        setLoading(false);
        throw err;
      }
    }
  };

  const setter = ( func ) => {
    return async (...args) => {
      if(isLoading === true)
        return;
      setLoading(true);
      try{
        const newRaw = await func(...args, raw);
        if(newRaw !== false){
          setRaw(newRaw);
          setFlag(1 - flag);
        }else{
          setLoading(false);
        }
      }catch(err){
        setLoading(false);
        throw err;
      }
    };
  };


  useEffect(() => {
    (async() => {
      setComputed(await transformer(raw));
      setLoading(false);
    })();
  }, [ flag ]);

  return [
    computed,
    trigger,
    setter,
    isLoading,
  ];
};

export default useLine;