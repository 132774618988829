import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import AppContext from '../AppContext';
import useLine from '../useLine';

import { api_host } from '../config';

function AppointmentStore(polyclinicId, initDate) {
  const appContext = useContext(AppContext);
  const [ date, setDate ] = useState(initDate);
  const [ order, setOrder ] = useState({
    field: 'q_num',
    direction: 'asc'
  });
  const [ filter, setFilter ] = useState({
    field: 'all',
    value: ''
  });
  const [ rowPerPage ] = useState(10);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ numberOfPage, setNumberOfPage ] = useState(1);
  const [ polyclinicName, setPolyclinicName] = useState('');

  const sortData = (data, { field, direction }) => {
    const dir = (direction === 'desc')?-1:1;
    const t = [...data];
    t.sort((a, b) => {
      return ((a[field] < b[field])?-1:1) * dir;
    })
    return t;
  };

  const filterData = (data, { field, value }) => {
    const fields = ['customer_name', 'date_birth', 'medrec','bpjs'];
    if(value === '')
      return data;
    else if(field !== 'all')
      return data.filter(el => el[field].toLowerCase().includes(value));
    else
      return data.filter(el => fields.reduce((stat, curField) => {
        return el[curField].toLowerCase().includes(value) || stat;
      }, false));
  };

  const transformer = ( raw ) => {
    const first = (currentPage - 1) * rowPerPage;
    const last = currentPage * rowPerPage;
    let qnum = 1;
    raw.forEach( row => {
      row.q_num = qnum++;
    });
    const filtered = filterData(raw,filter);
    setNumberOfPage(parseInt(Math.ceil(filtered.length / rowPerPage)) || 1);
    const sorted = sortData(filtered, order);
    return sorted.map((row, idx) => ({
      ...row, 
      idx: idx + 1 // record number by the order
    }))
    .slice(first, last)
    .map( record  => ({
      ...record,
      date_birth: moment(record.date_birth,'YYYY-MM-DD').format('DD-MM-YYYY')
    }));
  }

  const [ computed, trigger, setter, isLoading ] = useLine([], transformer)

  const fetchData = setter(async (paramDate) => {
    try{
      const { data } = await axios.get(`${api_host}api/appointments/?date=${paramDate}&polyclinic_id=${polyclinicId}`);
      setNumberOfPage(parseInt(Math.ceil(data.data.length / rowPerPage)) || 1);
      setPolyclinicName(data.polyclinic_name);
      setDate({
        today: data.date,
        tomorrow: data.nextDate,
        yesterday: data.prevDate
      });
      return data.data
    }catch(err){
      appContext.addNotifs('danger', 'Gagal mengambil data', 3000);
      return [];
    }
  });

  const update = setter(async (id, value, raw) => {
    try{
      await axios.patch(`${api_host}api/appointments?id=${id}`, value);
      const changed = [...raw];
      const idx = changed.findIndex(val => val.id === id);
      changed[idx] = {
        ...changed[idx],
        ...value
      };
      return changed;
    }catch(err){
      appContext.addNotifs('danger', 'Gagal mengubah data', 3000);
      return [];
    }
  });

  const getDayIdn = (date) => {
    const dayEn = moment(date.today, 'YYYY-MM-DD').format('dddd');
    let dayIdn = '';
    const dateFmt= moment(date.today,'YYYY-MM-DD').format('DD-MM-YYYY')
    if(dayEn === 'Monday')
      dayIdn = 'Senin';
    if(dayEn === 'Tuesday')
      dayIdn = 'Selasa'
    if(dayEn === 'Wednesday')
      dayIdn = 'Rabu'
    if(dayEn === 'Thursday')
      dayIdn = 'Kamis'
    if(dayEn === 'Friday')
      dayIdn = 'Jumat'
    if(dayEn === 'Saturday')
      dayIdn = 'Sabtu'
    if(dayEn === 'Sunday')
      dayIdn = 'Minggu';
    return `${dayIdn}, ${dateFmt}`;
  }

  useEffect(() => {
    fetchData(date.today || date);
  }, [ polyclinicId ]);

  return{
    isLoading,
    computed,
    polyclinicName,
    date: getDayIdn(date),
    filterField: filter.field,
    filterValue: filter.value,
    orderField: order.field,
    orderDirection: order.direction,
    currentPage,
    numberOfPage,
    fetchData,
    update,
    nextDate: () => {
      setCurrentPage(1);
      setFilter({
        ...filter,
        value: ''
      });
      fetchData(date.tomorrow);
    },
    prevDate: () => {
      setCurrentPage(1);
      setFilter({
        ...filter,
        value: ''
      });
      fetchData(date.yesterday);
    },
    setFilterField: trigger((field) => {
      setCurrentPage(1);
      setFilter({
        ...filter,
        field
      });
    }),
    setFilterValue: trigger((value) => {
      setCurrentPage(1);
      setFilter({
        ...filter,
        value
      });
    }),
    setOrderField: trigger((field) => {
      setOrder({
        ...order,
        field
      });
    }),
    setOrderDirection: trigger((direction) => {
      if(! direction)
        direction = (order.direction === 'asc')?'desc':'asc';
      setOrder({
        ...order,
        direction
      });
    }),
    firstPage: trigger(() => {
      setCurrentPage(1);
    }),
    nextPage: trigger(() => {
      if(currentPage < numberOfPage){
        setCurrentPage(currentPage + 1);
      }else return false;
    }),
    prevPage: trigger(() => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
      }else return false;
    }),
    lastPage: trigger(() => {
      setCurrentPage(numberOfPage);
    }),
  };
}

export default AppointmentStore;
