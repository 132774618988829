import React from 'react';
import { Button } from 'react-bootstrap';

const ToggleButton = (props) => {
  return(
      <Button
        variant={(props.value)?'success':'primary'}
        onClick={props.onClick}
        disabled={props.isLoading}
        size="sm"
        className="mr-1"
      >
        {props.children}
      </Button>
  );
};

export default ToggleButton;