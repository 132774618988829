import React, { useState } from 'react';
import CustomerStore from '../stores/customer.store';
import {
  Table,
  Form,
  Button,
  InputGroup,
  Overlay,
  Popover,
  ButtonGroup,
  ButtonToolbar,
  Row,
  Col
} from 'react-bootstrap';
import Octicon, { Search } from '@primer/octicons-react'
import LoadingSpinner from '../components/LoadingSpinner';

const CustomerPage = () =>{
  const {
    computed,
    isLoading,
    searchById,
    deleteById
  } = CustomerStore();

  const [ id, setId ] = useState('');

  const [ target, setTarget ] = useState(null);
  const [ deleteId, setDeleteId ] = useState(0);

  const handleDelete = () => {
    deleteById(deleteId);
    setDeleteId(0);
    setTarget(null);
  }

  const confirmDelete = (ev) => {
    setDeleteId(ev.target.dataset.id);
    setTarget(ev.target);
  }

  const cancelConfirmDelete = () => {
    setDeleteId(0);
    setTarget(null);
  };

  const handleSearch = (ev) => {
    ev.preventDefault();
    searchById(id);
  }

  return (
    <>
      <Row>
        <Col>
          <h2>Administrasi</h2>
        </Col>
      </Row>
      <LoadingSpinner loading={isLoading} />
      <Form onSubmit={handleSearch}>
        <InputGroup>
          <Form.Control 
            value={id} 
            type="number"
            onChange={(ev) => setId(ev.target.value)}
            placeholder="Cari Id Akun..."
          />
          <InputGroup.Append>
            <Button type="submit"><Octicon icon={Search}/> Cari</Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
      <Table bordered>
        <thead>
          <tr>
            <td>#</td>
            <td>Nama</td>
            <td>Tanggal Lahir</td>
            <td>No Rekas Medis</td>
            <td>No BPJS</td>
            <td>Kelurahan</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
        {computed.map( (row, idx) => (
          <tr key={row.id}>
            <td>{idx + 1}</td>
            <td>{row.name}</td>
            <td>{row.date_birth}</td>
            <td>{row.medrec}</td>
            <td>{row.bpjs}</td>
            <td>{row.area}</td>
            <td>
              <Button onClick={confirmDelete} data-id={row.id} variant="danger">Hapus</Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <Overlay
        target={target}
        show={target !== null}
      >
        <Popover id="popover-basic" title="Konfirmasi Hapus">
          <p>Apakah anda yakin?</p>
          <ButtonToolbar className="d-flex flex-column">
            <ButtonGroup>
              <Button onClick={handleDelete} variant="danger">Ya</Button>
              <Button onClick={cancelConfirmDelete} variant="secondary">Tidak</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Popover>
      </Overlay>
    </>
  );
}

export default CustomerPage;
