import React from 'react';
import Octicon, { ChevronDown, ChevronUp, Dash } from '@primer/octicons-react'

const TableHeader = (props) => {
  const {
    orderDirection,
    orderField,
    field
  } = props;
  const iconSort = () => {
    if(field !== orderField)
      return <Octicon icon={Dash} size='small' verticalAlign='middle'/>
    else {
      if(orderDirection === 'asc')
        return <Octicon icon={ChevronUp} size='small' verticalAlign='middle'/>
      else
        return <Octicon icon={ChevronDown} size='small' verticalAlign='middle'/>
    }
  };
  return(
    <td onClick={props.onClick.bind(null, field)} style={{cursor:'pointer'}}>
      <h6>{props.children} {iconSort()}</h6>
    </td>
  );
};

export default TableHeader;